import { createSlice } from '@reduxjs/toolkit';
import {
    addEntity,
    deleteEntity,
    getEntity,
    getEntitys,
    getEntitysNotPaginated,
    updateEntity,
    getEntityTypeNotPaginated
} from './entityActions';

const initialState = {
    total_pages: 0,
    total_items: 0,
    items_per_page: 0,
    current_page: 0,
    current_page_items: 0,
    error: false,
    EntitysLoading: false,
    Entitysuccess: false,
    EntityMsg: '',
    Entitys: [],
    Entity: null,
    EntityTypes: []
};

const Entity = createSlice({
    name: 'Entity',
    initialState,
    reducers: {
        clearEntityMessages: (state) => {
            state.error = false;
            state.EntityMsg = '';
            state.Entitysuccess = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getEntitys.pending, (state) => {
            state.EntitysLoading = true;
        });
        builder.addCase(getEntitys.fulfilled, (state, action) => {
            state.EntitysLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.Entitys = action.payload?.data;
            state.error = action.payload?.error;
            state.EntityMsg = action.payload?.msg;
            state.Entitysuccess = !action.payload?.error;
        });
        builder.addCase(getEntity.fulfilled, (state, action) => {
            state.EntitysLoading = false;
            state.Entity = action.payload?.data[0];
            state.error = action.payload?.error;
            state.EntityMsg = action.payload?.msg;
            state.Entitysuccess = !action.payload?.error;
        });
        builder.addCase(getEntitysNotPaginated.fulfilled, (state, action) => {
            state.EntitysLoading = false;
            state.Entitysuccess = !action.payload?.error;
            state.Entitys = action.payload?.data;
            state.error = action.payload?.error;
            state.EntityMsg = action.payload?.msg;
        });
        builder.addCase(addEntity.pending, (state) => {
            state.EntitysLoading = true;
        });
        builder.addCase(addEntity.fulfilled, (state, action) => {
            state.EntitysLoading = false;
            state.error = action.payload?.error;
            state.Entitysuccess = !action.payload?.error;
            state.EntityMsg = action.payload?.msg;
        });
        builder.addCase(updateEntity.pending, (state) => {
            state.EntitysLoading = true;
        });
        builder.addCase(updateEntity.fulfilled, (state, action) => {
            state.EntitysLoading = false;
            state.error = action.payload?.error;
            state.Entitysuccess = !action.payload?.error;
            state.EntityMsg = action.payload?.msg;
        });
        builder.addCase(deleteEntity.fulfilled, (state, action) => {
            state.EntitysLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.Entitys = action.payload?.data.items;
            state.error = action.payload?.error;
            state.EntityMsg = action.payload?.msg;
            state.Entitysuccess = !action.payload?.error;
        });
        builder.addCase(getEntityTypeNotPaginated.fulfilled, (state, action) => {
            state.EntityTypes = action.payload?.data;
        });
    }
});

export const { clearEntityMessages } = Entity.actions;

export default Entity.reducer;

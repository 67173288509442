// App.js
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { useLocation } from 'react-router-dom';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// Add this detection at the top level
const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

// Prevent pull-to-refresh and browser navigation gestures on mobile
if ('ontouchstart' in document.documentElement) {
    document.body.style.overscrollBehavior = 'none';

    // Fix for iOS momentum scrolling
    document.addEventListener(
        'touchmove',
        (e) => {
            if (e.target.closest('.ps') || e.target.closest('.ps__rail-x') || e.target.closest('.ps__rail-y')) {
                // Allow scrolling within PerfectScrollbar containers
                e.stopPropagation();
            }
        },
        { passive: true }
    );
}

// Add this to your App.js at the top
// This suppresses the findDOMNode warning in development
if (process.env.NODE_ENV !== 'prod') {
    // Temporarily suppress findDOMNode deprecation warnings
    const originalConsoleError = console.error;
    console.error = (...args) => {
        if (args[0] && typeof args[0] === 'string' && args[0].includes('findDOMNode')) {
            // Skip logging this specific warning
            return;
        }
        originalConsoleError(...args);
    };
}

const NavigationScroll = ({ children }) => {
    const location = useLocation();
    const { pathname } = location;

    useEffect(() => {
        // Scroll to top on route change
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });

        // Fix for mobile Safari overscroll issues
        document.body.style.overscrollBehavior = 'none';

        return () => {
            document.body.style.overscrollBehavior = 'auto';
        };
    }, [pathname]);

    return children;
};

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);

    useEffect(() => {
        // Force passive event listeners for all touch events on mobile
        if (isMobile) {
            // Force refresh if mobile has caching issues
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.getRegistrations().then((registrations) => {
                    for (let registration of registrations) {
                        registration.update();
                    }
                });
            }

            // Override addEventListener to make touch events passive by default
            const originalAddEventListener = EventTarget.prototype.addEventListener;

            EventTarget.prototype.addEventListener = function (type, listener, options) {
                if (type.startsWith('touch')) {
                    let newOptions = options;
                    if (typeof newOptions === 'object') {
                        newOptions.passive = true;
                    } else {
                        newOptions = { passive: true };
                    }
                    return originalAddEventListener.call(this, type, listener, newOptions);
                }
                return originalAddEventListener.call(this, type, listener, options);
            };
        }
    }, []);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <Routes />
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;

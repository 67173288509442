// assets
import {
    IconDashboard,
    IconHomeDollar,
    IconCurrencyDollar,
    IconTool,
    IconShieldCheck,
    IconClipboardData,
    IconFileInvoice,
    IconReportMoney,
    IconBuildingStore
} from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconHomeDollar,
    IconCurrencyDollar,
    IconTool,
    IconClipboardData,
    IconFileInvoice,
    IconShieldCheck,
    IconReportMoney,
    IconBuildingStore
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'pages',
    caption: 'Pages',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            icon: icons.IconDashboard,
            url: 'tenant/dashboard',
            target: false
        },
        {
            id: 'rnpl',
            title: 'SafeLease',
            type: 'item',
            icon: icons.IconShieldCheck,
            url: 'tenant/safelease',
            target: false
        },
        {
            id: 'payments',
            title: 'Payments',
            type: 'item',
            icon: icons.IconCurrencyDollar,
            url: 'tenant/payments',
            target: false
        },
        // {
        //     id: 'shieldplus',
        //     title: 'ShieldPlus',
        //     type: 'item',
        //     icon: icons.IconShieldCheck,
        //     url: 'tenant/shieldplus',
        //     target: false
        // },

        // {
        //     id: 'renters-marketplace',
        //     title: 'Marketplace Offers',
        //     type: 'item',
        //     icon: icons.IconBuildingStore,
        //     url: 'tenant/offers',
        //     target: false
        // },

        {
            id: 'credit-building',
            title: 'Credit Building',
            type: 'item',
            icon: icons.IconReportMoney,
            url: 'tenant/credit-building',
            target: false
        },
        {
            id: 'applications',
            title: 'Applications',
            type: 'item',
            icon: icons.IconClipboardData,
            url: 'tenant/applications',
            target: false
        }
        // {
        //     id: 'maintenance',
        //     title: 'Maintenance',
        //     type: 'item',
        //     icon: icons.IconTool,
        //     url: 'tenant/maintenance',
        //     target: false
        // }
    ]
};

export default pages;
